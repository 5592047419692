export enum MetricsType {
    OSA_SKU = 'osa_sku',
    OSA_Facing = 'osa_facing',
    OSA_on_Shelf = 'osa_on_shelf',
    SOS = 'sos',
    OSA = 'osa',
    BrandBlock = 'brand_block',
    PriceRange = 'price_range',
    DisplayWidth = 'display_width',
    Planogramm = 'planogramm'
}

export const MetricsLocale = {
    [MetricsType.OSA]: 'OSA',
    [MetricsType.SOS]: 'sfa_tasks.sos',
    [MetricsType.BrandBlock]: 'Brand block',
    [MetricsType.PriceRange]: 'Price Execution',
    [MetricsType.DisplayWidth]: 'Display width',
    [MetricsType.Planogramm]: 'Planogram',
};
