import { onActivated, toRefs } from 'vue-demi';
import { useRoute } from 'vue-router/composables';

import useAppStore, { Breadcrumb } from '~/stores/appStore';
import type { FullPageOption } from '~/stores/appStore';
import useRecentPagesStore from '~/stores/recentPagesStore';

interface PageOptions {
    title: string,
    defaultPadding?: boolean,
    fixedHeader?: boolean,
    fullPage?: boolean | FullPageOption,
    showBack?: boolean,
    breadcrumbs?: Breadcrumb[],
    titleShadow?: boolean,
    darkBackground?: boolean,
    mobileHeaderBorder?: boolean,
    teleportId?: string,
    backLink?: string,
}

function getBackLink(routePath: string) {
    const path = routePath.split('/');
    if (!path.pop()) {
        path.pop();
    }
    return path.join('/');
}

function getFullPageOption(value?: boolean | FullPageOption): FullPageOption {
    if (value === undefined) return { desktop: false, mobile: false };
    if (typeof value === 'object') return value;
    return { desktop: value, mobile: value };
}

export default function usePageOptions(options: PageOptions) {
    const appStore = useAppStore();
    const route = useRoute();
    const resentPagesStore = useRecentPagesStore();

    const {
        title,
        defaultPadding,
        fixedHeader,
        fullPage,
        backLink,
        breadcrumbs,
        titleShadow,
        darkBackground,
        headerTeleportId,
        mobileHeaderBorder,
    } = toRefs(appStore);

    onActivated(() => {
        title.value = options.title;
        defaultPadding.value = options.defaultPadding === undefined ? true : options.defaultPadding;
        fixedHeader.value = options.fixedHeader === undefined ? false : options.fixedHeader;
        fullPage.value = getFullPageOption(options.fullPage);
        breadcrumbs.value = options.breadcrumbs || [];
        titleShadow.value = options.titleShadow || false;
        darkBackground.value = options.darkBackground || false;
        headerTeleportId.value = options.teleportId || route.name as string || '';

        const showBack = options.showBack === undefined ? true : options.showBack;
        backLink.value = showBack ? (options.backLink || getBackLink(route.path)) : '';

        mobileHeaderBorder.value = Boolean(options.mobileHeaderBorder);

        resentPagesStore.addItem({
            name: title.value,
            route: route.path,
            breadcrumbs: appStore.breadcrumbs,
            timestamp: new Date(),
        });
    });

    return {
        options,
        backLink,
    };
}
