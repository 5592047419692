import { MetricsType, MetricsLocale } from '~/components/metrics/MetricsType';

export default {
    metrics: {
        schema: {
            list: {
                pk: {
                    type: 'string',
                    locale: 'global.id',
                },
                external_id: {
                    type: 'string',
                    locale: 'global.external_id',
                },
                name: {
                    type: 'string',
                    locale: 'global.name',
                },
                type: 'string',
                params: 'string',
                is_disabled: {
                    type: 'int',
                    locale: 'global.is_active',
                },
            },
            create: {
                is_disabled: {
                    type: 'bool',
                    locale: 'global.is_disabled',
                },
                type: {
                    required: true,
                    type: 'list',
                    default: MetricsType.SOS,
                    options: [
                        {
                            id: MetricsType.SOS,
                            locale: MetricsLocale.sos,
                        },
                        {
                            id: MetricsType.BrandBlock,
                            locale: MetricsLocale.brand_block,
                        },
                        {
                            id: MetricsType.PriceRange,
                            locale: MetricsLocale.price_range,
                        },
                        {
                            id: MetricsType.DisplayWidth,
                            locale: MetricsLocale.display_width,
                        },
                        {
                            id: MetricsType.Planogramm,
                            locale: MetricsLocale.planogramm,
                        },
                    ],
                },
                name: {
                    type: 'string',
                    locale: 'global.name',
                },
                external_id: {
                    type: 'string',
                    locale: 'global.external_id',
                },
                params: 'other',
            },
        },
    },
    stores: {
        schema: {
            list: {
                name: 'string',
                territory: 'string',
                city_name: {
                    type: 'string',
                    locale: 'city.name',
                },
                region_name: {
                    type: 'string',
                    locale: 'region.name',
                },
                address: 'string',
                external_id: {
                    type: 'string',
                    locale: 'stores.external_id_short',
                },
                external_id2: {
                    type: 'string',
                    locale: 'stores.external_id2_short',
                },
                active_matrices_count: {
                    type: 'int',
                    locale: 'stores.matrix_count',
                },
            },
        },
    },
};
