import { reactive, watch } from 'vue-demi';
import { defineStore } from 'pinia';

import { useI18n } from '~/plugins/i18n';

const useDevStore = defineStore('dev', () => {
    const i18n = useI18n();

    const dev = reactive({
        locale: i18n.locale,
        spa: false,
        // stitching: false,
        // stitchingFront: false,
        stitchingBack: false,
        // Visits
        realogramLine: undefined,
        realogramFaceId: false,
        // Mobile
        mobileSchema: false,
        // Pages
        users: false,
        assortment: false,
        salesLocations: false,
        scenesGroups: false,
        massRecount: false,
    });

    /*************************************************
     * Locale
     *************************************************/
    watch(() => dev.locale, () => {
        i18n.setLocale(dev.locale);
    });

    watch(() => i18n.locale, () => {
        dev.locale = i18n.locale;
    });

    return {
        dev,
    };
});

export default useDevStore;
